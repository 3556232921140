.BottomPlayer {
  height: 125px;
  background: white;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}

.BottomPlayer__topRow, .BottomPlayer__midRow, .BottomPlayer__bottomRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.BottomPlayer__topRow {
  flex-direction: column;
}

.BottomPlayer .BottomPlayer__bottomRow > div {
  margin: 2px;
}

.BottomPlayer .BottomPlayer__bottomRow .Progress {
  width: 100%;
  margin: 0 8px
}

.BottomPlayer .Icon {
  color: #333
}

.BottomPlayer__artist {
  color: #555;
  font-size: small;
  margin-top: 3px;
}